import React from "react";
import Chat from "../../Components/general/chat/Chat";
import Header from "../../Layouts/header/header";

const blogs = () => {
  return (
    <>
      <Header />
      <Chat />
    </>
  ); 
};

export default blogs;
